.beat-player {
    width: 100%;
    margin: 0 auto;
    padding: 12px 20px;
    background: white;
    border-radius: 12px;
}

.beat-title h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1.5rem;
    text-align: center;
}

.waveform {
    position: relative;
    padding: 10px 20px 20px;
    margin-bottom: 0.5rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.time-display {
    position: absolute;
    right: 20px;
    top: -25px;
    color: #4353FF;
    font-size: 0.875rem;
    z-index: 1;
    background: rgba(255, 255, 255, 0.9);
    padding: 4px 8px;
    border-radius: 4px;
}

.controls-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.25rem;
    border-radius: 12px;
    margin: 0.25rem auto;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.waveform wave {
    overflow: visible !important;
}

.waveform > wave > canvas, 
.waveform > wave > wave {
    filter: drop-shadow(0 4px 6px rgba(67, 83, 255, 0.2));
}

.player-controls {
    margin: 0.75rem 0;
}

.control-button {
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    color: #4353FF;
}

.control-button:hover {
    background: rgba(67, 83, 255, 0.1);
}

.control-button.play {
    background: #4353FF;
    color: white;
}

.control-button.play:hover {
    background: #3142cc;
}

.volume-slider {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    color: #4353FF;
    padding: 0 1rem;
}

.volume-slider .slider {
    -webkit-appearance: none;
    flex: 1;
    height: 4px;
    border-radius: 2px;
    outline: none;
    transition: all 0.2s;
}

.volume-slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #4353FF;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: all 0.2s;
}

.volume-slider .slider::-webkit-slider-thumb:hover {
    transform: scale(1.1);
}

.volume-value {
    min-width: 48px;
    font-size: 0.875rem;
    color: #4a5568;
}

.beat-info {
    margin: 0.75rem 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.info-item {
    background: rgba(67, 83, 255, 0.05);
    padding: 0.5rem 1rem;
    min-width: 70px;
}

.info-item span {
    font-size: 0.75rem;
    color: #4a5568;
    margin-bottom: 0.25rem;
}

.info-item strong {
    color: #2d3748;
    font-weight: 600;
}

.beat-structure {
    margin: 0.75rem auto;
    padding: 0.5rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.beat-structure span {
    color: #4a5568;
    font-size: 0.875rem;
}

.beat-structure strong {
    color: #2d3748;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.75rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1.25rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.action-button.download {
    background: rgba(67, 83, 255, 0.05);
    color: #4353FF;
    transition: all 0.2s ease;
}

.action-button.download:hover {
    background: #4353FF;
    color: white;
}

.action-button.license {
    background: #4353FF;
    color: white;
}

.action-button.license:hover {
    background: #3142cc;
}

@media (max-width: 640px) {
    .action-buttons {
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .action-button {
        flex: 0 1 auto;
    }
}
